// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';
import * as functions from 'firebase/functions';
// Add the Firebase products that you want to use
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIR_APIKEY,
  authDomain: process.env.VUE_APP_FIR_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIR_DATABASEURL,
  projectId: process.env.VUE_APP_FIR_PROJECTID,
  storageBucket: process.env.VUE_APP_FIR_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIR_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIR_APPID,
  measurementId: process.env.VUE_APP_FIR_MEASUREMENTID,
};

// Initialize Firebase
const firApp = initializeApp(firebaseConfig);
const firFunctionsConfig = functions.getFunctions(firApp, 'europe-west6');
const firFirestore = getFirestore(firApp);
const firAuth = getAuth(firApp);
firAuth.settings = {
  appVerificationDisabledForTesting: false,
};
const Fb = {

  // eslint-disable-next-line no-param-reassign
  install(Vue) {
    if (Number(process.env.VUE_APP_USE_FUNCTIONS_EMULATOR)) {
      functions.connectFunctionsEmulator(firFunctionsConfig, 'localhost', 5001);
    }
    if (Number(process.env.VUE_APP_USE_AUTH_EMULATOR)) {
      connectAuthEmulator(firAuth, 'http://localhost:9099');
    }
    if (Number(process.env.VUE_APP_USE_FIREBASE_EMULATOR)) {
      connectFirestoreEmulator(firFirestore, 'localhost', 8080);
    }
    const api = (method, ...args) => functions
      .httpsCallable(firFunctionsConfig, `admin-api/method/${method}`)(...args)
      .catch((error) => {
        this.$toast.error(`API: ${error.message}`, {
          type: 'error',
          dismissible: true,
          duration: 5000,
        });
        // Vue.prototype.$errorToast(`API: ${error.message}`);
        throw error;
      });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$fb = firApp;
    // eslint-disable-next-line no-param-reassign
    Vue.$fb = firApp;
    // eslint-disable-next-line no-param-reassign
    Vue.$fbauth = firAuth;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$fbauth = firAuth;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = api;
    // eslint-disable-next-line no-param-reassign
    Vue.$api = api;
    // eslint-disable-next-line no-param-reassign
    Vue.$firestore = firFirestore;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$firestore = firFirestore;
  },
};

export default Fb;
